import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

//types
import { CLOSE_BANNER_DIALOG } from "../../store/banner/types";

//action
import { createNewBanner, editBanner } from "../../store/banner/action";
import { permissionError } from "../../util/Alert";
import AWS from 'aws-sdk';
import { baseURL, key , aws_access_key , aws_secret_access_key , aws_bucket_name , aws_region } from "../../util/Config";

const S3_BUCKET = aws_bucket_name;
const REGION = aws_region;
const ACCESS_KEY = aws_access_key;
const SECRET_ACCESS_KEY = aws_secret_access_key;

AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
});
const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const config = {
  dirName: "profile/new",
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
}


const BannerDialog = (props) => {
  const [file, setFile] = useState([]);
  const dispatch = useDispatch();

  const { dialog: open, dialogData } = useSelector((state) => state.banner);
  const hasPermission = useSelector((state) => state.admin.admin.flag);

  const [mongoId, setMongoId] = useState("");
  const [link, setLink] = useState("");
  const [imageData, setImageData] = useState(null);
  const [imagePath, setImagePath] = useState(null);

  const [errors, setError] = useState({
    link:"",
    image: "",
  });

  useEffect(() => {
    if (dialogData) {
      setMongoId(dialogData._id);
      setLink(dialogData.URL);
      setImagePath(dialogData.image);
    }
  }, [dialogData]);

  useEffect(
    () => () => {
      setError({
        link:"",
        image: "",
      });
      setMongoId("");
      setLink("");
      setImageData(null);
      setImagePath(null);
    },
    [open]
  );

  useEffect(() => {
    window.onbeforeunload = closePopup();
  }, []);

  const HandleInputImage = (e) => {
    if (e.target.files[0]) {
      setImageData(e.target.files[0]);
      // const reader = new FileReader();
      // reader.addEventListener("load", () => {
      //   setImagePath(reader.result);
      // });
      // reader.readAsDataURL(e.target.files[0]);
      console.log(e.target.files[0]);
      setImageData(e.target.files[0]);
      const selectedFile = e.target.files[0]; // Get file from input
      setFile(selectedFile);
      console.log("Uploading file:", selectedFile);
      const params = {
        Bucket: S3_BUCKET,
        Key: `uploads/${selectedFile.name}`, // File path in S3
        Body: selectedFile,
        ACL: "public-read", // Make file publicly accessible (if needed)
      };    
      s3.upload(params, (err, data) => {
        if (err) {
          console.error("Error uploading file:", err);
        } else {
          console.log("File uploaded successfully:", data.Location);
          setImagePath(data.Location);
        }
      });    
    }
    if (!e.target.files[0]) {
      return setError({
        ...errors,
        image: "Image is Required!",
      });
    } else {
      return setError({
        ...errors,
        image: "",
      });
    }
  };

  const closePopup = () => {
    dispatch({ type: CLOSE_BANNER_DIALOG });
  };

  const isLink = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^(ftp|http|https):\/\/[^ "]+$/.test(val);
    return validNumber;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
        const urlValid=isLink(link)
   if(!link || !urlValid){
    const error={}
        if(!link){
          error.link="Link Is Required!"
        }else if(!urlValid){
          error.link="Link Invalid!"
        }
        return setError({ ...error });
   }else{
    if (!mongoId) {
      if (!imageData || !imagePath) {
        return setError({ ...errors, image: "Banner Image is Required!" });
      }
    } else {
      if (!imageData && !imagePath) {
        return setError({ ...errors, image: "Banner Image is Required!" });
      }
    }

    if (!hasPermission) return permissionError();
    const formData = new FormData();

    formData.append("image", imageData);
    formData.append("URL", link);
    formData.append("imagePath", imagePath);
    if (mongoId) {
      props.editBanner(mongoId, formData);
    } else {
      props.createNewBanner(formData);
    }
   }
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4"> Banner </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="form-group">
                  <label className="mb-2 text-gray">Link</label>
                  <input
                    type="text"
                    className="form-control"
                    required=""
                    placeholder="https://www.google.com"
                    value={link}
                    onChange={(e) => {
                      setLink(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...errors,
                          link: "Link is Required!",
                        });
                      } else {
                        return setError({
                          ...errors,
                          link: "",
                        });
                      }
                    }}
                  />
                    {errors.link && (
                    <div className="ml-2 mt-1">
                      {errors.link && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.link}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="form-group mt-4">
                  <label className="mb-2 text-gray">Banner Image</label>
                  <input
                    type="file"
                    className="form-control form-control-sm"
                    accept="image/*"
                    required=""
                    onChange={HandleInputImage}
                  />
                  {errors.image && (
                    <div className="ml-2 mt-1">
                      {errors.image && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.image}</span>
                        </div>
                      )}
                    </div>
                  )}
                  {imagePath && (
                    <>
                      <img
                        height="70px"
                        width="70px"
                        alt="app"
                        src={imagePath}
                        style={{
                          boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                          // border: "2px solid #fff",
                          borderRadius: 10,
                          marginTop: 10,
                          float: "left",
                          objectFit: "cover",
                        }}
                      />
                    </>
                  )}
                </div>
                <div className={imagePath ? "mt-5 pt-5" : "mt-5"}>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { createNewBanner, editBanner })(BannerDialog);




















// import React, { useEffect, useState } from "react";
// import { connect, useDispatch, useSelector } from "react-redux";
// import {
//   Dialog,
//   DialogContent,
//   DialogTitle,
//   IconButton,
//   Tooltip,
// } from "@material-ui/core";
// import { Cancel } from "@material-ui/icons";
// import { CLOSE_BANNER_DIALOG } from "../../store/banner/types";
// import { createNewBanner, editBanner } from "../../store/banner/action";
// import { permissionError } from "../../util/Alert";
// import AWS from "aws-sdk";

// const BannerDialog = (props) => {
//   const dispatch = useDispatch();

//   const { dialog: open, dialogData } = useSelector((state) => state.banner);
//   const hasPermission = useSelector((state) => state.admin.admin.flag);

//   const [mongoId, setMongoId] = useState("");
//   const [link, setLink] = useState("");
//   const [imageData, setImageData] = useState(null);
//   const [imagePath, setImagePath] = useState(null);

//   const [errors, setError] = useState({
//     link: "",
//     image: "",
//   });

//   useEffect(() => {
//     if (dialogData) {
//       setMongoId(dialogData._id);
//       setLink(dialogData.URL);
//       setImagePath(dialogData.image);
//     }
//   }, [dialogData]);

//   useEffect(() => () => {
//     setError({
//       link: "",
//       image: "",
//     });
//     setMongoId("");
//     setLink("");
//     setImageData(null);
//     setImagePath(null);
//   }, [open]);

//   const HandleInputImage = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setImageData(file);
//       const reader = new FileReader();
//       reader.onload = () => {
//         setImagePath(reader.result);
//       };
//       reader.readAsDataURL(file);
//     } else {
//       setError((prev) => ({ ...prev, image: "Image is Required!" }));
//     }
//   };

//   const uploadToS3 = async (file) => {
//     const S3_BUCKET = "bogo-s3";
//     const REGION = "us-east-1";

//     const s3 = new AWS.S3({
//       accessKeyId: "AKIA6G75DMULM5TYWOV2",
//       secretAccessKey: "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",
//       region: REGION,
//     });

//     const params = {
//       Bucket: S3_BUCKET,
//       Key: `${Date.now()}-${file.name}`, // Unique file name
//       Body: file,
//       ContentType: file.type,
//       ACL: "public-read",
//     };

//     try {
//       const { Location } = await s3.upload(params).promise();
//       return Location; // Return the uploaded file's URL
//     } catch (err) {
//       console.error("S3 Upload Error:", err);
//       throw new Error("Failed to upload image");
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!link) {
//       setError((prev) => ({ ...prev, link: "Link is Required!" }));
//       return;
//     }

//     if (!mongoId && !imageData) {
//       setError((prev) => ({ ...prev, image: "Image is Required!" }));
//       return;
//     }

//     if (!hasPermission) {
//       permissionError();
//       return;
//     }

//     try {
//       let imageUrl = imagePath;

//       if (imageData) {
//         imageUrl = await uploadToS3(imageData);
//         console.log("image url: ", imageUrl);
        
//       }

//       const formData = {
//         URL: link,
//         image: imageUrl,
//       };

//       if (mongoId) {
//         props.editBanner(mongoId, formData);
//       } else {
//         props.createNewBanner(formData);
//       }

//       closePopup();
//     } catch (err) {
//       console.error(err);
//       alert("Failed to submit banner. Please try again.");
//     }
//   };

//   const closePopup = () => {
//     dispatch({ type: CLOSE_BANNER_DIALOG });
//   };

//   return (
//     <Dialog
//       open={open}
//       onClose={closePopup}
//       disableBackdropClick
//       disableEscapeKeyDown
//       fullWidth
//       maxWidth="xs"
//     >
//       <DialogTitle>
//         <span className="text-danger font-weight-bold h4">Banner</span>
//         <IconButton
//           style={{ position: "absolute", right: 0 }}
//           onClick={closePopup}
//         >
//           <Tooltip title="Close">
//             <Cancel className="text-danger" />
//           </Tooltip>
//         </IconButton>
//       </DialogTitle>

//       <DialogContent>
//         <form>
//           <div className="form-group">
//             <label>Link</label>
//             <input
//               type="text"
//               className="form-control"
//               placeholder="https://example.com"
//               value={link}
//               onChange={(e) => setLink(e.target.value)}
//             />
//             {errors.link && <p className="text-danger">{errors.link}</p>}
//           </div>

//           <div className="form-group">
//             <label>Banner Image</label>
//             <input
//               type="file"
//               className="form-control"
//               accept="image/*"
//               onChange={HandleInputImage}
//             />
//             {errors.image && <p className="text-danger">{errors.image}</p>}
//             {imagePath && (
//               <img
//                 src={imagePath}
//                 alt="Preview"
//                 style={{ height: 70, width: 70, marginTop: 10 }}
//               />
//             )}
//           </div>

//           <button
//             type="button"
//             className="btn btn-outline-info"
//             onClick={closePopup}
//           >
//             Close
//           </button>
//           <button
//             type="submit"
//             className="btn btn-danger ml-2"
//             onClick={handleSubmit}
//           >
//             Submit
//           </button>
//         </form>
//       </DialogContent>
//     </Dialog>
//   );
// };

// export default connect(null, { createNewBanner, editBanner })(BannerDialog);
