// exports.baseURL = "https://admin.vivins.online/"; // YOUR_BASE_URL   //same as backend
// exports.baseURL = "https://service.bogosindia.com/"; // YOUR_BASE_URL   //same as backend

exports.baseURL = "https://servicebogo.bogosindia.com/"; // YOUR_BASE_URL   //same as backend


// exports.baseURL = "http://localhost:5000/"; // YOUR_BASE_URL   //same as backend

exports.key = "vCbxDkOUno8XaWj6Nob06aIKrejZ9R4h"; // YOUR_SECRET_KEY  // same as backend;

exports.aws_access_key = "AKIA6G75DMULOHCHT55L";
exports.aws_secret_access_key = "hHfzZSmCUIDEFk4kXENu3xjY9zhTqQw6GOwajHnE";
exports.aws_bucket_name = "bogo-s3";
exports.aws_region = "ap-south-1";
